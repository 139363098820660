import { Fragment, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Carousel } from 'primereact/carousel';
import { chunkArray } from '@/common/function';

const clients = [
    {
        url: '/img/client/ABInBev.png',
        alt: 'sprite'
    },
    {
        url: '/img/client/BASF-LOGO.jpg',
        alt: 'pepsi'
    }, {
        url: '/img/client/Budweiser.png',
        alt: 'vedan'
    }, {
        url: '/img/client/CocaCola.png',
        alt: 'heineken'
    }, {
        url: '/img/client/Sumitomo.png',
        alt: 'cocacola'
    }, {
        url: '/img/client/shopee.png',
        alt: 'cocacola'
    },
]

const ListClient = () => {
    const dimensions = useSelector(x => x.dimensions.windowSize);

    const clientTemplate = (client) => {
        return (
            <img src={client.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                alt={client.alt} className="product-image"
            />
        )
    }

    const renderBody = useMemo(() => {
        if (dimensions) {
            const {
                width,
            } = dimensions;
            if (width > 991.98) {
                // let htmlRow = [];

                // const data = chunkArray(clients, 5);
                // data.forEach((item, idx) => {
                //     let htmlCol = []
                //     item.forEach((items, idxs) => {
                //         htmlCol.push(
                //             <div style={{ width: '50%', padding: 10 }} className="d-flex justify-content-center align-items-center" key={idxs}>
                //                 <img src={items.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                //                     alt={items.alt} className="product-image" style={{ width: `70%`, objectFit: "contain", minHeight: 75, border: "1px solid #c4c4c4", borderRadius: 10 }}
                //                 />
                //             </div>
                //         )
                //     })
                //     htmlRow.push(
                //         <div className="d-flex justify-content-center" key={idx}>
                //             {htmlCol}
                //         </div>
                //     )
                // })

                return (
                    <Carousel value={clients} numVisible={5} numScroll={1} itemTemplate={clientTemplate} autoplayInterval={5000} circular={true}/>
                )
            } else {
                let htmlRow = [];


                const data = chunkArray(clients, 2);

                data.forEach((item, idx) => {
                    let htmlCol = []
                    item.forEach((items, idxs) => {
                        htmlCol.push(
                            <div style={{ width: '50%', padding: 10 }} className="d-flex justify-content-center align-items-center" key={idxs}>
                                <img src={items.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                    alt={items.alt} className="product-image" style={{ width: `70%`, objectFit: "contain", minHeight: 75, border: "1px solid #c4c4c4", borderRadius: 10, padding: 10 }}
                                />
                            </div>
                        )
                    })
                    htmlRow.push(
                        <div className="d-flex justify-content-center" key={idx}>
                            {htmlCol}
                        </div>
                    )
                })

                return htmlRow;
            }
        }
        return null;
    }, [dimensions])

    return (
        <Fragment>
            {renderBody}
        </Fragment>
    )
}

export default ListClient;