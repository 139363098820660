import {useRef, useEffect} from 'react';
import videojs from "video.js";
export const VideoJS = ( props ) => {

    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady } = props;
  
    useEffect(() => {
      // make sure Video.js player is only initialized once
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;
  
        const player = playerRef.current = videojs(videoElement, options, () => {
          onReady && onReady(player);
        });
      } else {
        // you can update player here [update player through props]
        // const player = playerRef.current;
        // player.autoplay(options.autoplay);
        // player.src(options.sources);
      }
    }, [options]);
  
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }, []);
  
    return (
      <div data-vjs-player style={{borderRadius: 30}}>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    );
  }
  
  export default VideoJS;