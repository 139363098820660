import { Row, Col, Container } from 'react-bootstrap';
import HeaderSEO from '@/shared/_seo';
import Layout from '@/shared/_layout';
import { Fragment, useMemo, useRef } from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import * as actions from '@/redux/actions/index';
import FormContact from '@module/contact';
import VideoJS from '@/components/videos';
import ListClient from '@/components/carousel/home'
import InfoApplications from '@/shared/_info';
import styled from 'styled-components';

const SectionImage = styled.section`
    margin-top: 80px;
    transition: 0.3s all ease-in-out;
    width: 100%;
    height: ${({ heightImage }) => (heightImage ? `${heightImage}px` : `100%`)};
    background-image: ${({ urlImage }) => (urlImage ? `url(${urlImage})` : `none`)};
    background-size: ${({ widthImage, heightImage, urlImage }) => (urlImage ? `${widthImage}px ${heightImage}px` : `100% 100%`)};
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;

    .button-image-lp:focus{
        box-shadow: none;
        outline: none;
    }

    .text-heading{
        text-align: left;
    }

    .heading-thin {
        font-weight: 100;
        font-size: 2.5vw;
        line-height: 1.5;
    }

    .heading-bold{
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 2.75vw;
        line-height: 1.25;
    }

    .container--fluid{
        padding-top: 22.5vh;
        padding-top: 11.5vh;
        max-width: 1800px;
        padding-left: 0;
        padding-right: 0;
    }

    .p-top-5{
        padding-top: 5vh;
    }

    @media only screen and (max-width: 1280px) {
        width: 100%;
        transition: 0.3s all ease-in-out;
        background-image: ${({ urlImage }) => (urlImage ? `url(${urlImage})` : `none`)};

        .text-heading{
            text-align: left;
            line-height: 2.5;
        }

        .heading-thin {
            font-size: 3vw;
            font-weight: 100;
            line-height: 1.5;
        }

        .heading-bold{
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 3.2vw;
            line-height: 1.25;
        }

        .container--fluid{
            max-width: 95%;
            padding-top: 6vh;
            padding-left: 0;
            padding-right: 0;
        }
    }


    @media only screen and (max-width: 1024px) {
        width: 100%;
        transition: 0.3s all ease-in-out;
        background-image: ${({ urlImage }) => (urlImage ? `url(${urlImage})` : `none`)};

        .text-heading{
            text-align: center;
            line-height: 2.5;
        }

        .heading-thin {
            font-size: 6.5vw;
            font-weight: 300;
            line-height: 1.5;
        }

        .heading-bold{
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 8.25vw;
            line-height: 1.25;
        }

        .container--fluid{
            max-width: 100%;
            padding-top: 20vh;
            padding-left: 10px;
            padding-right: 10px;
        }

        .p-top-5{
            padding-top: 15vh;
        }

        .button-image-lp{
            width: 50%;
            height: 65px;
            object-fit: contain;
        }
    }
    
`;

const IntroductionProduct = styled.div`
    .heading-bold{
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 2.75vw;
        line-height: 1.25;
    }

    ul{
        list-style: none;
        margin-top: 5vh;
        margin-bottom: 6vh;
        padding-left: 0;
    }

    ul > li {
        padding: 15px 0px;
        font-size: 1.25vw;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
    }


    .container--fluid{
        max-width: 1800px;
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 1120px) {
        ul > li {
            padding: 15px 0px;
            font-size: 2.25vh;
            font-weight: 700;
            font-family: 'Roboto', sans-serif;
            display: flex;
            align-items: center;
        }
    }
`

const SectionImage2 = styled.section`
    transition: 0.3s all ease-in-out;
    width: 100%;
    height: ${({ heightImage }) => (heightImage ? `${heightImage}px` : `100%`)};
    background-image: ${({ urlImage }) => (urlImage ? `url(${urlImage})` : `none`)};
    background-size: ${({ widthImage, heightImage, urlImage }) => (urlImage ? `${widthImage}px ${heightImage}px` : `100% 100%`)};
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    margin-top: 60px;

    @media only screen and (max-width: 1120px) {
        margin-top: 40px;
        width: 100%;
        height: ${({ heightImage }) => (heightImage ? `${heightImage}px` : `100%`)};
    }
`;

const SectionImage3 = styled.section`
    width: 100%!important;

    .heading-bold{
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 2.75vw;
        line-height: 1.25;
    }

    .heading-thin {
        font-size: 1vw;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        line-height: 2;
        display: block;
    }

    .vjs-big-play-button{
        left: calc(50% + 22.5px)
    }

    .container--fluid{
        max-width: 1800px;
        padding: 60px 0px;
    }

    .center-text{
        text-align: left;
        width: 100%;
    }

    .list-client{
        margin-top: 4.5vh;
    }

    @media only screen and (max-width: 1120px) {
        .center-text{
            text-align: center;
        }   

        .heading-bold{
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 8.25vw;
            line-height: 1.25;
        }

        .heading-thin{
            display: none;
        }
    }
`

export default function Home(){
    const playerRef = useRef(null);
    const dimensions = useSelector(x => x.dimensions.windowSize);

    const videoJsOptions = { 
        //lookup the options in the docs for more options
        autoplay: false,
        controls: true,
        responsive: true,
        poster: 'https://file.tengroup.com.vn/wwwroot/07072022/1Malx8b4Im5dfs9RrNssjxH8V74Tbe4XE0ph4cPzldWxvOdHGKG23PUuP1roPDGpEaN4Oz5wbGMSqtyF.png',
        fluid: true,
        sources: [{
            src: 'https://file01.tengroup.com.vn/octopusfile/public/uploads/videos/vtv.mp4',
            type: 'video/mp4'
        }]
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        //you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const renderSection1 = useMemo(() => {
        let urlBackground = "";
        let imageSizeWidth = 0;
        let imageSizeHeight = 0;

        if (dimensions) {
            const {
                width,
                height
            } = dimensions;

            if (width > height) {
                urlBackground = "/img/home/background-des.jpg";
                imageSizeWidth = width;
                imageSizeHeight = width * 1042 / 1666;
            } else {
                urlBackground = "/img/home/background-mb.jpg";
                imageSizeWidth = width;
                imageSizeHeight = width * 1618 / 828;
            }

            console.log(imageSizeWidth, imageSizeHeight, urlBackground);


            return (
                <SectionImage urlImage={urlBackground} widthImage={imageSizeWidth} heightImage={imageSizeHeight}>
                    <div className='container-fluid container--fluid'>
                        <div className='row'>
                            <div className='col-lg-6 text-heading'>
                                <h1 className="heading-bold" ><span className="heading-thin">Quản lý hoạt động Marketing</span><br />Chính xác, Minh bạch <br />Hiệu quả </h1>
                            </div>
                        </div>
                        <div className='row p-top-5'>
                            <div className='col-lg-6 text-heading'>
                                <Link legacyBehavior href="https://apps.apple.com/app/id1580530355">
                                    <input type="image" src="/img/icons/app-store.svg" className="button-image-lp" style={{ marginBottom: 10 }} />
                                </Link>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 text-heading'>
                                <Link legacyBehavior href="https://play.google.com/store/apps/details?id=com.tengroup.mobileoctopusapp">
                                    <input type="image" src="/img/icons/ch-play.svg" className="button-image-lp" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </SectionImage>
            );
        }
        return null;
    }, [dimensions])

    const renderSection2 = useMemo(() => {
        let urlBackground = "";
        let imageSizeWidth = 0;
        let imageSizeHeight = 0;
        if (dimensions) {
            const {
                width,
                height
            } = dimensions;

            if (width > height) {
                urlBackground = "/img/home/bg2-desc.jpg";
                imageSizeWidth = width;
                imageSizeHeight = width * 1028 / 1765;
            } else {
                urlBackground = "/img/home/bg2-mb.jpg";
                imageSizeWidth = width - 52;
                imageSizeHeight = (width - 52) * 963 / 786;
            }


            return (
                <IntroductionProduct>
                    {width < height ? (
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 text-center' style={{ marginTop: 40 }}>
                                    <span className='heading-bold' style={{ fontSize: `8.25vw` }}>Sản phẩm</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SectionImage2 urlImage={urlBackground} widthImage={imageSizeWidth} heightImage={imageSizeHeight}>
                                    </SectionImage2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 div-product' style={{ marginTop: 0 }}>
                                    <ul>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Tiết kiệm 70-80% chi phí</li>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Gia tăng tính minh bạch</li>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Cho kết quả chính xác</li>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Tương tác đa nền tảng</li>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Bảo chứng từ các công ty, tập đoàn đa quốc gia</li>
                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 10 }} />Thành viên Hiệp hội Phần mềm và Dịch vụ CNTT Việt Nam</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 text-center' style={{ marginBottom: 40 }}>
                                    {/* <Link legacyBehavior href="/introduction">
                                        <input type="image" src="/img/banner/button-kham-pha.png" className="button-image-lp" style={{ height: 75 }} />
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SectionImage2 urlImage={urlBackground} widthImage={imageSizeWidth} heightImage={imageSizeHeight}>
                            <div className='container-fluid container--fluid' style={{ height: `100%` }}>
                                <div className='row d-flex justify-content-end' style={{ height: `100%` }}>
                                    {width > height && (
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-12' style={{ paddingTop: `12vh` }}>
                                                    <span className='heading-bold'>Sản phẩm</span>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 div-product'>
                                                    <ul>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Tiết kiệm 70-80% chi phí</li>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Gia tăng tính minh bạch</li>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Cho kết quả chính xác</li>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Tương tác đa nền tảng</li>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Bảo chứng từ các công ty, tập đoàn đa quốc gia</li>
                                                        <li><img src="/img/icons/li-icon.svg" style={{ marginRight: 25 }} />Thành viên Hiệp hội Phần mềm và Dịch vụ CNTT Việt Nam</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    {/* <Link legacyBehavior href="/introduction">
                                                        <input type="image" src="/img/banner/button-kham-pha.png" className="button-image-lp" style={{ height: 75 }} />
                                                    </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </SectionImage2>
                    )}
                </IntroductionProduct>
            );
        }
        return null;
    }, [dimensions])

    const renderSection3 = useMemo(() => {
        return (
            <SectionImage3>
                <div className='container-fluid container--fluid'>
                    <div className='row'>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div className='center-text'>
                                <span className='heading-bold'>Video giới thiệu<br />
                                    Octopus</span>
                                <br />
                                <br />
                                <span className='heading-thin'>
                                    TEN Group là một trong những tập đoàn tiên phong trong việc ứng dụng Trí tuệ nhân tạo vào việc quản lý các hoạt động marketing bao gồm: Display audit, Activation, Event, Redemption, Thematic, POSM… giúp giảm thiểu chi phí thực hiện, tăng chất lượng và hiệu quả của tất cả các dự án.
                                </span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                        </div>
                    </div>
                </div>
            </SectionImage3>
        );
    }, [dimensions]);

    const renderSection4 = useMemo(() => {
        return (
            <SectionImage3 className="new_landing-text">
                <div className='container-fluid container--fluid'>
                    <div className='row'>
                        <div className='col-md-6 d-flex align-items-center'>
                            <div className='center-text'>
                                <span className='heading-bold'>Đối tác</span>
                            </div>
                        </div>
                    </div>
                    <div className='row list-client'>
                        <div className='col-md-12'>
                            <ListClient />
                        </div>
                    </div>
                </div>
            </SectionImage3>
        );
    }, [dimensions]);

    return (
        <>
            <HeaderSEO
                title="Trang chủ Octopus"
                description="Trang chủ Octopus"
                siteTitle="Trang chủ Octopus"
            />
            <Layout>
                {renderSection1}
                {renderSection2}
                {renderSection3}
                {renderSection4}
                <FormContact />
                <InfoApplications /> 
            </Layout>
        </ >
    )
}